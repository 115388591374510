@import "../../common/common.scss";

.bgWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;

    .parentWrapper {
        position: relative;
        width: 25%;
        height: 25%;
        background-repeat: no-repeat;
        background-color: #1F002E;;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .contentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 8%;
            font-family: Bebas Neue;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;

            .description1 {

            }
            .description2 {

            }

            .closeButtonWrapper {
                padding: 40px 0px 20px 0px;
                text-align: center;
                .closeButton {
                    @include gradientBackground;
                    border-radius: 4px;
                    width: 120px;
                    height: 40px;
                    outline: none;
                    border: none;
                    cursor: pointer;
                    color: #fff;
                    font-family: Avenir Next;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                }
            }

        }
    }
}