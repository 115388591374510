@import "../common.scss";
@import "../../ScreenBreakPoint.scss";

@mixin buttonSize {
    width: 217px;
    height: 57px;
    border-radius:8px; 
}

@mixin hollowButtonSize {
    width: 215px;
    height: 55px;
    border-radius:8px; 
}

@mixin textSize {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 57px;
    text-align: center;
    cursor: pointer;
}

.ctaButton {
    display: flex;
}

.ctaButtonCenter {
    display: flex;
    justify-content: center;
    align-items: center
}


.ctaButtonWrapper {
    position:relative;

    .ctaButtonFrame{
        @include buttonSize();
        @include gradientBackground();
    }

    .ctaButtonHollowFrame{
        @include hollowButtonSize();
        @include gradientHollowBackground();
        border:2px solid transparent; 
        -webkit-mask: 
        linear-gradient(#fff 0 0) padding-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out; 
        mask-composite: exclude;
    }

    .disabledCtaButtonFrame {
        @include buttonSize();
        background: #333;
    }

    .disabledText{
        @include buttonSize();
        @include textSize();
        position:absolute; 
        top:0; left:0; bottom:0; right: 0;
        color: #aaa;
    }

    .text{
        @include buttonSize();
        @include textSize();
        position:absolute; 
        top:0; left:0; bottom:0; right: 0;
        color: #fff;
    }
}

.ctaButtonWrapper:hover {
    .text {
        font-weight: bold;
    }
}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}

.descriptionFontEn {
    @include descriptionFontEn();
}

.descriptionFontZhHK {
    @include descriptionFontZhHK();
}