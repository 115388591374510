@import '../../common/common.scss';
@import '../../ScreenBreakPoint.scss';

.bgWrapper {
    position: relative;
    min-width: $page-min-width;
    min-height: $page-min-height;
    background-repeat: no-repeat;
    background-color: #170022;

    @include breakpoint(medium) {
        background-size: contain;
    }

    .contentWrapper {
        background-image: url('../homepage/images/background.png');
        min-height: $page-min-height;

        .titleWrapper {
            display: flex;
            justify-content: center;
            padding: 40px 0px 80px 0px;
            .title {
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 80px;

                text-align: center;
                background: linear-gradient(91.85deg, #D964F0 26.3%, #45F6FC 94.43%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                height: 100px;
            }
        }

        .walletWrapper {
            display: flex;
            justify-content: center;
            padding: 0px 0px 0px 0px;

            .walletSection {
                position: relative;
                padding: 10px 20px 20px 20px;
                border-radius: 8px;
                background: #fff;
                color: #000;
                margin: 0px 20px 0px 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .overlay {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0%;
                    left: 0%;
                    border-radius: 8px;
                    background:rgba(80, 80, 80, 0.8);
                }

                .logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 0px 20px 0px;
                    width: 320px;
                    min-height: 140px;
                }

                .description {
                    min-height: 40px;
                    max-width: 320px;
                    margin: 0% 5% 0% 5%;
                    font-family: Avenir;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    padding: 10px 0px 10px 0px;
                }

                .loadingIconWrapper {
                    min-height: 215px;
                    display: flex;
                    align-items: center;
                }

                .ctaButtonSection {
                    min-height: 175px;
                    margin: 20px 0px 20px 0px;
                    .ctaButtonWrapper {
                        margin: 20px 0px 20px 0px;
                    }

                    .createWallet {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 20px 0px 20px 0px;
                        font-family: Avenir Next;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;

                        .howIcon {
                            padding: 0px 5px 0px 0px;
                            cursor: pointer;
                        }

                        .createWalletLink {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .createWalletText {
                                margin: 0px 5px 0px 0px;
                            }

                            .createButton {
                                margin: 0px 0px 0px 5px;
                                font-family: Avenir Next;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                color: purple;
                                padding: 0px;
                                outline: none;
                                border: none;
                                cursor: pointer;
                                background: none;
                            }

                        }

                    }
                }
            }
        }

        .howToConnect {
            padding: 40px 0px 40px 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            .howIconWhite {
                cursor: pointer;
            }
            .howToButton {
                color: #fff;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                outline: none;
                border: none;
                cursor: pointer;
                background: none;
            }
        }
    }
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
    }
}
.loadingIcon {
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
}