// iphone
$screen-sm-min: 375px;

//Desktop small
$screen-md-min: 1152px;

//WideScreen
$screen-lg-min: 1440px;


@mixin breakpoint($point) {
    @if $point == small {
        @media (min-width: $screen-sm-min) {
            @content;
        }
    }
    @else if $point == medium {
        @media (min-width: $screen-md-min) {
            @content;
        }
    }
    @else if $point == large {
        @media (min-width: $screen-lg-min) {
            @content;
        }
    }
}