@import "../../ScreenBreakPoint.scss";
@import "../../common/common.scss";

.progressBar {
    padding: 20px 0px 40px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    .slotWrapper {
        width: 800px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .slotOn {
            width: 37px;
            height: 37px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            @include gradientBackground;
            .step {
                text-align: center;
                line-height: 37px;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                text-align: center;
                color: #FFFFFF;
            }
            .slotTitle {
                margin: 10px 0 0 0 ;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                text-align: center;
                color: #FFFFFF;
                word-break:keep-all
            }
        }

        .slotOff {
            width: 37px;
            height: 37px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border: 2px solid rgba(255, 255, 255, 0.7);
            .step {
                text-align: center;
                line-height: 37px;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                text-align: center;
                color: #FFFFFF;
            }
            .slotTitle {
                margin: 10px 0 0 0 ;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
                text-align: center;
                color: #FFFFFF;
                word-break:keep-all
            }
        }
        .progressLineOn {
            margin: 40px 20px 20px 20px;
            margin-bottom: 35px;
            height: 2px;
            width: 60px;
            background: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%);
        }
        .progressLineOff {
            margin: 40px 20px 20px 20px;
            margin-bottom: 35px;
            height: 2px;
            width: 60px;
            background: grey;
        }
    }

}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}

.descriptionFontEn {
    @include descriptionFontEn();
}

.descriptionFontZhHK {
    @include descriptionFontZhHK();
}