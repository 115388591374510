@import '../ScreenBreakPoint.scss';

$content_top_margin: 80px;
$content_bottom_margin: 80px;

$mobile-default-title-font-size: 64px;
$mobile-default-paragraph-font-size: 16px;
$mobile-page-min-width: 300px;
$mobile-page-min-height: 768px;
$default-title-font-size: 64px;
$default-paragraph-font-size: 20px;
$homepage-min-width: 1280px;
$homepage-horizontal-padding: 10%;
$homepage-section-vertical-margin: 40px;
$page-min-width: 1280px;
$page-min-height: 768px;
$bg-color: #170022;

@mixin common() {
    font-family: Avenir Next;
    font-style: normal;
    font-size: 12px;
}

@mixin gradientFont() {
    background: linear-gradient(91.85deg, #D964F0 26.3%, #45F6FC 94.43%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin gradientBackground() {
    background: linear-gradient(95.93deg, #D964F0 17.12%, #45F6FC 108.52%);
}

@mixin gradientHollowBackground() {
    background: linear-gradient(95.93deg, #D964F0 17.12%, #45F6FC 108.52%) border-box; 
}

@mixin gradientBorder() {
    border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;
}

@mixin commonMargin() {
    margin: auto 15%;
}

@mixin popupBg {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(15, 0, 22, 0.7);
    backdrop-filter: blur(21px);
    -webkit-backdrop-filter: blur(21px);
}

@mixin titleFontEn {
    font-family: "Bebas Neue", sans-serif;
}

@mixin descriptionFontEn {
    font-family: "Avenir Next", sans-serif;
}

@mixin titleFontZhHK {
    font-family: "Noto Sans TC", sans-serif;
}

@mixin descriptionFontZhHK {
    font-family: "Noto Sans TC", sans-serif;
}