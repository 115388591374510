@import "../../common/common.scss";
@import "../../ScreenBreakPoint.scss";

.bgWrapper {

    @include popupBg();
    .popupWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 720px;
        width: 60%;
        min-height: 540px;
        height: 60%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .closeButtonWrapper {
            .closeButton {
                position: absolute;
                background: none;
                border: none;
                top: -50px;
                right: 0px;
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                color: #fff;
                cursor: pointer;
            }
        }

        .contentWrapper {
            margin: 5% 15% 5% 15%;

            .title {
                margin: 0px 0px 20px 0px;
                @include gradientFont();
                font-style: normal;
                font-weight: bold;
                font-size: 80px;
                line-height: 100px;
            }

            .description {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
            }

            .priceSection {
                .priceTitle {
                    text-align: left;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                }
                .priceWrapper {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    margin: 20px 0px 20px 0px;
                    .priceIconWrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .priceTitle {
                            word-break:keep-all;
                            margin: 0px 10px 0px 0px;
                        }
                        .priceIcon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 113px;
                            height: 55px;
                            background: rgba(255, 255, 255, 0.2);
                            border-radius: 8px;
                            margin: 0px 10px 0px 0px;
                            .iconName {
                                margin: 0px 0px 0px 5px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 20px;
                            }
                        }
                    }

                    .wethInput {
                        width: 358px;
                        height: 50px;
                        padding: 0px 0px 0px 10px;
                        border-radius: 8px;
                        background: rgba(255, 255, 255, 0.2);
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        color: #fff;
                    }
                    .wethInput::-webkit-outer-spin-button, .wethInput::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                   }
                }
            }

            .termsSection {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px 0px 20px 0px;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                .checkBox {
                    width: 20px;
                    height: 20px;
                    margin: 0px 5px 0px 0px;
                }
            }
        }
    }

    .inProgressWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 650px;
        width: 40%;
        min-height: 450px;
        height: 40%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .closeButtonWrapper {
            .closeButton {
                position: absolute;
                background: none;
                border: none;
                top: -50px;
                right: 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                color: #fff;
                cursor: pointer;
            }
        }

        .contentWrapper {
            margin: 5% 15% 5% 15%;

            .title {
                padding: 0px 0px 20px 0px;
                @include gradientFont();
                font-style: normal;
                font-weight: bold;
                font-size: 72px;
            }

            .description {
                padding: 20px 0px 0px 0px;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
            }
        }
    }

    .transactionDone {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 650px;
        width: 40%;
        min-height: 450px;
        height: 40%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .closeButtonWrapper {
            .closeButton {
                position: absolute;
                background: none;
                border: none;
                top: -50px;
                right: 0px;
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                color: #fff;
                cursor: pointer;
            }
        }

        .contentWrapper {
            margin: 5% 15% 5% 15%;

            .title {
                margin: 0px 0px 20px 0px;
                @include gradientFont();
                font-style: normal;
                font-weight: bold;
                font-size: 64px;
            }

            .description {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
            }
        }
    }

    .transactionFailed {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 650px;
        width: 40%;
        min-height: 450px;
        height: 40%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .closeButtonWrapper {
            .closeButton {
                position: absolute;
                background: none;
                border: none;
                top: -50px;
                right: 0px;
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                color: #fff;
                cursor: pointer;
            }
        }

        .contentWrapper {
            margin: 5% 15% 5% 15%;

            .title {
                margin: 0px 0px 20px 0px;
                @include gradientFont();
                font-style: normal;
                font-weight: bold;
                font-size: 82px;
            }

            .description {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
            }
        }
    }
}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}

.descriptionFontEn {
    @include descriptionFontEn();
}

.descriptionFontZhHK {
    @include descriptionFontZhHK();
}