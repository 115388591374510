@import '../../common/common.scss';

.pricingWrapper {
    padding: 20px 0px 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ethIcon {
        margin: 0px 5px 0px 0px;
    }

    .ethWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        height: fit-content;
        white-space: nowrap;

        .ethPrice {
            display: inline-block;
            @include titleFontEn();
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 40px;
            margin: 0px 5px 0px 0px;
        }

        .ethUnit {
            @include titleFontEn();
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            align-self: flex-end;
        }
    }

    .hkPriceWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        height: fit-content;
        white-space: nowrap;

        .vline {
            margin: 0px 5px 0px 5px;
            @include titleFontEn();
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            align-self: flex-end;
        }

        .hkPrice {
            @include titleFontEn();
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            align-self: flex-end;
        }
    }
}