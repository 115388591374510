@import '../../common/common.scss';

.bgWrapper {
    display: flex;
    background-repeat: no-repeat;
    background-image: url('./images/wrapper.png');
    background-size: contain;

    .titleWrapper {
        text-align: left;
        margin-left: 60px;
        flex-direction: column;
        display: flex;
        justify-content: space-around;
        // justify-content: space-evenly;
        min-height: 106px;

        .clubTitle {
            margin: 8px 0 0 0 ;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
        }

        .itemTitle {
            margin: 0 0 8px 0 ;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
        }
    }
}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}

.descriptionFontEn {
    @include descriptionFontEn();
}

.descriptionFontZhHK {
    @include descriptionFontZhHK();
}