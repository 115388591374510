@import "../../common/common.scss";
@import "../../ScreenBreakPoint.scss";

.bgWrapper {

    @include popupBg();
    .popupWrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 650px;
        width: 45%;
        min-height: 300px;
        height: 45%;
        background: #1F002E;
        border: 4px solid;
        border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;

        .closeButtonWrapper {
            .closeButton {
                position: absolute;
                background: none;
                border: none;
                top: -50px;
                right: 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                color: #fff;
                cursor: pointer;
            }
        }

        .contentWrapper {
            width: 80%;
            margin: 5% 15% 5% 15%;

            .title {
                margin: 0px 0px 20px 0px;
                @include gradientFont();
                font-family: Bebas Neue;
                font-style: normal;
                font-weight: bold;
                font-size: 45px;
            }

            .description {
                margin: 0px 0px 20px 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
            }

            .content {
                margin: 0px 0px 20px 0px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                margin: 30px 50px 0px 50px;

                table {
                    width: 100%;
                    thead {
                        tr {
                            th {
                                text-align: center;
                                padding: 4px;
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                text-align: center;
                                padding: 4px;
                            }
                            th {
                                text-align: center;
                                padding: 4px;
                            }
                        }
                    }
                }
            }
            .ctaSection {
                margin: 25px 0px 0px 0px;
            }
        }
    }
}