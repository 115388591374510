@import "../../common/common.scss";
@import "../../ScreenBreakPoint.scss";

.parentWrapper {
    .contentWrapper {
        margin: 100px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: $page-min-height;

        .title {
            width: 800px;
            margin: 0px 0px 40px 0px;
            font-style: normal;
            font-weight: bold;
            font-size: 100px;
            line-height: 100px;
            text-align: center;
            @include gradientFont();
        }

        .collectionWrapper {

            .loadingWrapper {
                margin-top: 80px;
            }

            .itemList {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                .itemWrapper {
                    padding: 40px 40px 40px 40px;
                    margin: 20px 20px 20px 20px;
                    border: 4px solid;
                    @include gradientBorder();

                    .descriptionWrapper {
                        .name {
                            text-align: center;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 35px;
                            color: #FFFFFF;
                        }
                        .loading {
                            position: relative;
                            top: 20px;
                            text-align: center;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 35px;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .noItemMsgWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 980px;
                height: 421px;
                background: rgba(255, 255, 255, 0.13);

                .noItemMsg {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 60px;
                    line-height: 60px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}

.descriptionFontEn {
    @include descriptionFontEn();
}

.descriptionFontZhHK {
    @include descriptionFontZhHK();
}