@import "../../../ScreenBreakPoint.scss";
@import "../../../common/common.scss";

.bgWrapper {
    @include popupBg();
    align-items: flex-start;    
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100vh;
    overflow-y: scroll;

    .container {
        min-width: 640px;
        max-width: 1600px;
        width: 65%;
        min-height: 640px;
        height: 60%;

        .closeButtonWrapper {
            display: flex;
            justify-content: flex-end;
            text-align: right;
            .closeButton {
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                position: relative;
                color: #fff;
                cursor: pointer;
            }
        }

        .popupWrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 40px;
            padding-bottom: 40px;            
            background: #1F002E;
            border: 4px solid;
            border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;
            overflow-y: scroll;

            .progressBarWrapper {
                width: 80%;
                margin: 0px;
            }

            .contentWrapper {
                .idleWrapepr {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;   
                    .descriptionWrapper{
                        .title {
                            @include gradientFont;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 64px;
                        }

                        .itemImage {
                            padding: 10px 0px 10px 0px;
                        }

                        .name {
                            font-style: normal;
                            font-weight: bold;
                            font-size: 32px;
                            line-height: 40px;
                            color: #FFFFFF;
                            padding: 10px 0px 20px 0px;
                        }

                        .line {
                            position: absolute;
                            left: 0;
                            right: 0;
                            margin-left: auto;
                            margin-right: auto;
                            width: 80%;
                            height: 2px;
                            background: linear-gradient(91.07deg, #D964F0 49.17%, #45F6FC 52.69%);
                        }

                        .price {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 36px;
                            color: #FFFFFF;
                            padding: 20px 0px 10px 0px;
                        }

                        .terms {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 0px 10px 0px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            text-align: center;
                            .checkbox {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        .ctaSection {
                            padding: 10px 0px 10px 0px;
                        }

                        .howToPurchase {
                            display: flex;
                            margin-top: 10px;
                            justify-content: center;
                            align-items: center;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 27px;
                            padding: 0px 0px 10px 0px;
                        }
                    }
                }

                .transactionWrapper {

                    .imageWrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 20px 0px 20px 0px;
                        height: 30%;

                        .userImage {
                            position: relative;
                            top:-10px;
                                width: 120px;
                                height: 120px;
                                border-radius: 60px;
                                background:  #fff;
                                display: flex;
                                flex-flow: column;
                            .icon{
                                align-self: center;
                                position: relative;
                                top:10px;
                                width: 100px;
                                height: 100px;
                            }
                            .address{
                                align-self: center;
                                position: relative;
                                top: 30px;
                                font-weight: bold;
                                font-style: normal;
                                font-size: 20px;
                            }
                        }

                        .horizontalLine {
                            .icon {
                                padding: 20px 40px 20px 40px;
                                width: 80px;
                                height: 80px;
                                color:  rgba(255, 255, 255, 0.2);
                            }
                        }
                    }

                    .instructionWrapper {
                        padding: 20px 0px 0px 0px;
                        .title {
                            padding: 0px 0px 20px 0px;
                            @include gradientFont;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 64px;
                        }

                        .description {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;

                            .display {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 10px 10px 20px 10px;

                                .finalAnimation {
                                    width: 320px;
                                    height: 320px;
                                    margin: 10px 10px 10px 10px;
                                }

                                .finalImage {
                                    width: 320px;
                                    height: 320px;
                                    margin: 10px 10px 10px 10px;
                                }
                            }
                        }

                        .loading {
                            margin: 40px 0px 0px 0px;
                        }

                        .ctaWrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .gotoHomePageWrapper {
                                margin: 20px;
                                .gotoHomePageButton {
                                    width: 217px;
                                    height: 57px;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 20px;
                                    color: #fff;
                                    background: none;
                                    border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;
                                    border-radius: 8px;
                                    cursor: pointer;
                                }
                            }
                            .collectionWrapper {
                                margin: 20px;
                                .collectionButton {
                                    width: 217px;
                                    height: 57px;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 20px;
                                    color: #fff;
                                    @include gradientBackground();
                                    border-radius: 8px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .luckyDrawWrapper {

                    .openBox {
                        margin: 40px 0px 20px 0px;
                        width: 320px;
                        border: 2px solid #D964F0;
                    }

                    .instructionWrapper {
                        padding: 20px 0px 0px 0px;
                        .title {
                            padding: 0px 0px 20px 0px;
                            @include gradientFont;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 64px;
                        }

                        .description {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                        }
                    }
                }

                .luckyDrawDoneWrapper {

                    .instructionWrapper {
                        padding: 20px 0px 0px 0px;
                        .title {
                            padding: 0px 0px 20px 0px;
                            @include gradientFont;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 64px;
                        }

                        .description {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            margin: 0px 0px 20px 0px;

                            .display {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 20px 10px 20px 20px;

                                .finalAnimation {
                                    width: 160px;
                                    height: 160px;
                                    margin: 10px 10px 10px 10px;
                                    border: 2px solid #D964F0;
                                }

                                .finalImage {
                                    width: 320px;
                                    height: 320px;
                                    margin: 10px 10px 10px 10px;
                                }
                            }
                            .message {
                                margin: 40px 0px 20px 0px;
                            }

                            .loadingWrapper {
                                margin: 20px 0px 20px 0px;
                            }
                        }

                        .ctaWrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .gotoHomePageWrapper {
                                margin: 20px;
                                .gotoHomePageButton {
                                    width: 217px;
                                    height: 57px;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 20px;
                                    color: #fff;
                                    background: none;
                                    border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;
                                    border-radius: 8px;
                                    cursor: pointer;
                                }
                            }
                            .collectionWrapper {
                                margin: 20px;
                                .collectionButton {
                                    width: 217px;
                                    height: 57px;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 20px;
                                    color: #fff;
                                    @include gradientBackground();
                                    border-radius: 8px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .transactionFailedWrapper {
                    height: 100%;
                    .instructionWrapper {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .title {
                            padding: 20px 0px 20px 0px;
                            @include gradientFont;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 72px;
                        }

                        .description {
                            margin: 0% 10% 0% 10%;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 40px;
                            color: #FFFFFF;
                            padding: 40px 0px 40px 0px;
                        }

                        .error {
                            font-style: normal;
                            font-size: 20px;
                            line-height: 40px;
                            color: #fff;
                            padding: 40px 0px 40px 0px;
                        }

                        .ctaWrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .gotoHomeWrapper {
                                margin: 20px;
                                .gotoHome {
                                    width: 217px;
                                    height: 57px;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 20px;
                                    color: #fff;
                                    background: none;
                                    border-image: linear-gradient(110.43deg, #D964F0 21.05%, #45F6FC 100%) 1;
                                    border-radius: 8px;
                                    cursor: pointer;
                                }
                            }
                            .tryAgainWrapper {
                                margin: 20px;
                                .tryAgain {
                                    width: 217px;
                                    height: 57px;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 20px;
                                    color: #fff;
                                    @include gradientBackground();
                                    border-radius: 8px;
                                    cursor: pointer;
                                }
                            }
                        
                        }
                        .contactUs {
                            width: 217px;
                            height: 57px;
                            font-style: normal;
                            color: #fff;
                            cursor: pointer;
                            .mailto {
                                font-weight: 600;
                                font-size: 18px;
                                color: #fff;
                            }
                        }    
                    }
                }
            }

        }

        .popupWrapper::-webkit-scrollbar {
            background: black;
            width: 10px;
        }

        .popupWrapper::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            background: black;
            width: 10px;
            border-radius: 10px;
        }

        .popupWrapper::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 5px grey;
            @include gradientBackground;
            width: 10px;
            border-radius: 10px;
        }
    }
}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}

.descriptionFontEn {
    @include descriptionFontEn();
}

.descriptionFontZhHK {
    @include descriptionFontZhHK();
}