@import "../common.scss";
@import "../../ScreenBreakPoint.scss";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .dot1 {
        margin: 20px 20px 20px 20px;
        background: #D964F0;
        animation: jump1 0.5s none linear infinite;
    }

    .dot2 {
        margin: 20px 20px 20px 20px;
        background: #7788FF;
        animation: jump1 0.5s none 0.1s linear infinite;
    }

    .dot3 {
        margin: 20px 20px 20px 20px;
        background: #45F6FC;
        animation: jump1 0.5s none 0.2s linear infinite;
    }
}

@keyframes jump1 {
    0% { transform: translate(0%, -50%)}
    50% { transform: translate(0%, -200%)}
    100% { transform: translate(0%, -50%)}
}