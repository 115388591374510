.bgWrappper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
}