@import "../../common/common.scss";
@import "../../ScreenBreakPoint.scss";

.wrapper {
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    .detail {
        display: flex;
        justify-content: left;
        align-items: center;
        font-style: normal;
        font-weight: 400;
    }
    .terms {
        display: flex;
        justify-content: left;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
    }
}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}

.descriptionFontEn {
    @include descriptionFontEn();
}

.descriptionFontZhHK {
    @include descriptionFontZhHK();
}