@import "../../common/common.scss";
@import "../../ScreenBreakPoint.scss";

.howToBid {
    display: flex;
    justify-content: left;
    align-items: center;
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 27px;
    cursor: pointer;

    .logo {
        margin-right: 10px;
    }
}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}

.descriptionFontEn {
    @include descriptionFontEn();
}

.descriptionFontZhHK {
    @include descriptionFontZhHK();
}