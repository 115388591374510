@import '../../../ScreenBreakPoint.scss';
@import '../../../common/common.scss';

.bgWrapper {
    position: relative;
    margin: 40px 0px 40px 0px;

    .itemImage {
        width: 100%;
        display: inline-block;
    }

    .wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        min-width: 320px;
        width: 40%;
        transform: translate(0%, -50%);
        text-align: left;

        .contentWrapper {
            .titleSection {
                .title {
                    font-size: 64px;
                    background: linear-gradient(91.73deg, #FFFFFF 45.8%, #45F6FC 68.92%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-style: normal;
                    font-weight: bold;
                }

                .title2 {
                    width: 300px;
                    font-size: 64px;
                    background: linear-gradient(91.73deg, #FFFFFF 45.8%, #45F6FC 68.92%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-style: normal;
                    font-weight: bold;
                }
            }
        }
    }
}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}