@import "../../ScreenBreakPoint.scss";
@import "../../common/common.scss";

.bgWrapper {
    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 40px 5% 80px 5%;
        box-sizing: border-box;
        min-width: $page-min-width;
        min-height: $page-min-height;

        .imageWrapper {
            display: block;
            margin-right: 80px;
            .collectionImage {
                width: 40vw;
                min-width: 380px;
                max-width: 480px;
            }
        }

        .contentWrapper {
            text-align: left;
            .clubTitle {
                padding: 0px 0px 10px 0px;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
            }

            .itemTitle {
                padding: 10px 0px 20px 0px;
                width: 520px;
                font-style: normal;
                font-weight: bold;
                font-size: 72px;
                @include gradientFont();
            }

            .description {
                padding: 20px 0px 40px 0px;
                width: 400px;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 190%;
            }

            .memberSection {
                .memberTitle {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 35px;
                }

                .memberIconSection {
                    display: block;
                    margin: 10px 0px 10px 0px;
                    .memberIconRow {
                        display: flex;
                        justify-content: left;
                        .memberIcon {
                            margin: 10px 10px 10px 0px;
                            width: 64px;
                            height: 64px;
                        }
                    }
                }
            }

            .pricingTitle {
                padding: 10px 0px 10px 0px;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 35px;
            }

            .drawSection {
                display: block;
                display: flex;
                text-align: center;

                .drawCard {
                    padding: 20px 10px 20px 10px;
                    margin-right: 40px;
                    background: rgba(255, 255, 255, 0.15);
                    border-radius: 4px;
                    .title {
                        .drawPart1 {
                            margin: 0px 5px 0px 0px;
                        }
                        display: flex;
                        justify-content: center;
                        .drawNumber {
                            font-weight: bold;
                            color: rgba(69, 246, 252, 1)
                        }
                        padding: 0px 0px 0px 0px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 27px;
                        .drawPart3 {
                            margin: 0px 0px 0px 5px;
                        }
                    }

                    .ctaButtonWrapper {
                        display: block;
                        padding: 5px 0px 5px 0px;
                        .ctaButton {
                            width: 217px;
                            height: 57px;
                            @include gradientBackground();
                            color: #fff;
                            border-radius: 8px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 27px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }

                    .probability {
                        cursor: pointer;
                        .probabilityIcon {
                            margin-right: 5px;
                        }
                        margin-top: 10px;
                        margin-left: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-top: 10px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 27px;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
    }

}

.titleFontEn {
    @include titleFontEn();
}

.titleFontZhHK {
    @include titleFontZhHK();
}

.descriptionFontEn {
    @include descriptionFontEn();
}

.descriptionFontZhHK {
    @include descriptionFontZhHK();
}