@import '../../../common/common.scss';
@import '../../../ScreenBreakPoint.scss';

.bgWrapper {
    .wrapper {
        position: relative;
        min-width: $homepage-min-width;
        box-sizing: border-box;
        padding: $homepage-section-vertical-margin $homepage-horizontal-padding $homepage-section-vertical-margin $homepage-horizontal-padding;
        display: flex;
        align-items: center;
        text-align: left;

        .contentWrapper {
            width: 40%;
            margin-right: 40px;
            z-index: 1;

            .titleSection {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .clubTitle {
                    padding-bottom: 10px;
                    font-family: Bebas Neue;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 40px;
                    line-height: 40px;
                }

                .itemTitle {
                    padding-top: 10px;
                    font-family: Bebas Neue;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 64px;
                    width: 500px;
                    line-height: 100px;
                    @include gradientFont();
                }
                padding-bottom: 20px;
            }

            .description {
                padding-top: 20px;
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 35px;
                margin: 0px 0px 40px 0px;
            }

            .memberSection {
                .memberIconSection {
                    margin: 20px 0px 20px 0px;
                    .memberIconRow {
                        display: flex;
                        justify-content: left;

                        .memberIcon {
                            margin: 10px 10px 10px 0px;
                            width: 63px;
                            height: 63px;
                        }
                    }
                }
            }

            .memberTitle {
                font-family: Avenir Next;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 35px;
            }

            .ctaButtonWrapper {
                padding-top: 20px;
                display: flex;
                justify-content: left;

                .ctaButton {
                    width: 217px;
                    height: 57px;
                    border-radius: 8px;
                    font-family: Avenir Next;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 27px;
                    text-align: center;
                    text-decoration: none;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include gradientBackground();
                }

                a {
                    text-decoration: none;
                    color: #fff;
                }

                a:visited {
                    text-decoration: none;
                    color: #fff;
                }
            }
        }

        .imageWrapper {
            position: absolute;
            right: 0px;
            display: block;
            z-index: 0;
            .drawItemImage {
                width: 50vw;
                min-width: 560px;
            }
        }

    }
}